import React from 'react';

const TextArea = (props) => {

    const {
        label,
        placeholder,
        value,
        onChange,
        readOnly,
        morse,
        children,
    } = props
return (
    <div className="w-full md:w-1/2 relative flex flex-col">
        <label className="text-white text-[1.2rem] text-left py-[1rem] whitespace-nowrap  ml-[2rem] hidden md:block">{label}</label>
        <textarea
        className={`resize-none w-full h-[26rem] pt-5 px-8 border text-[1.6rem] md:text-[2rem] border-gray-300 rounded-[30px] ${morse} font-[400]`}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        readOnly={readOnly}
        />

        {children}

    </div>
    );
};

export default TextArea;