import React, { useState } from 'react';
import InputField from './Input';
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import check from "../assets/img/check.png"

function Form(props) {

  const{
   text,
    morse
  } = props

  const [formData, setFormData] = useState({
    to: '',
    name: '',
    surname: '',
    agency: '',
    from: '',
    privacyCheckbox: false,
    newslettersCheckbox: false,
  });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: type === 'checkbox' ? checked : value
    }));
  };

  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('https://morse.codetalkers.eu/backend/public/api/send-email', {...formData, text, morse:btoa(morse)} );
      console.log(morse);
      navigate("/thankyou");
    } catch (error) {
      console.error('Error sending email', error);
    }
  };
  
  return (
    <form onSubmit={handleSubmit} className="py-[3rem] md:py-[4rem] mx-auto px-[1rem] md:px-[8rem] font-ChakraPetch  max-w-[1430px] ">
      <input type='hidden' value={morse} name="morse" />
      
      <input type='hidden' value={text} name="text" />

      <InputField
        type="email"
        name="to"
        value={formData.to}
        onChange={handleChange}
        placeholder="Mail destinatario*"
        required
      />
      <div className='md:flex md:flex-1 md:gap-5'>
      <InputField
        type="text"
        name="name"
        value={formData.name}
        onChange={handleChange}
        placeholder="Il tuo nome*"
        required
      />
      <InputField
        type="text"
        name="surname"
        value={formData.surname}
        onChange={handleChange}
        placeholder="Il tuo cognome*"
        required
      />
      </div>
      <InputField
        type="text"
        name="agency"
        value={formData.agency}
        onChange={handleChange}
        placeholder="Azienda/Organizzazione*"
        required
      />
      <InputField
        type="email"
        name="from"
        value={formData.from}
        onChange={handleChange}
        placeholder="La tua mail*"
        required
      />
      <div className='mt-10'>
        <label className="flex gap-6 text-white text-[18px] font-medium mb-6 text-left items-center">
          <input
            type="checkbox"
            name="privacyCheckbox"
            checked={formData.privacyCheckbox}
            onChange={handleChange}
            className="hidden"
            required
          />
            <span className="form-checkbox rounded-full border-2 h-[50px] min-w-[50px] bg-white flex justify-center items-center">
                {formData.privacyCheckbox && (
                  <img src={check} alt="" className="" />
                )}
            </span>
             <p>Autorizzo il trattamento dei dati personali secondo l’informativa resa ai sensi dell’art. 13 Dlgs. 196/2003 e dell’art. 13 del Reg. UE 679/2016, che dichiaro di aver letto e accettato.</p> 
        </label>
      </div>
      <div>
      <label className="flex gap-6 text-white text-[18px] font-medium mb-6 text-left items-center">
          <input
            type="checkbox" 
            name="newslettersCheckbox"
            checked={formData.newslettersCheckbox}
            onChange={handleChange}
            className="hidden"
          />
            <span className="form-checkbox rounded-full border-2  h-[50px] min-w-[50px] bg-white  flex justify-center items-center">
                {formData.newslettersCheckbox && (
                  <img src={check} alt="" className="" />
                )}
            </span>
            <p>Accetto di ricevere comunicazioni da Code Talkers e aderisco alla loro <a className='text-[#4703E4]' target='_blank' href="https://www.iubenda.com/privacy-policy/98581317/cookie-policy">Privacy Policy.</a> Tratteremo i tuoi dati in modo responsabile e sicuro. Puoi cancellare l’iscrizione in ogni momento. </p>
        </label>
      </div>

      <button type="submit" className="relative overflow-hidden hover:text-[#000] bg-[#4703E4] transition-all duration-500 text-white font-bold py-[1.81rem] px-[3.125rem] text-[2rem] rounded-full float-right btn">
         <span className='z-[2] relative'>Invia</span>
      </button>
     
    </form>
  );
}

export default Form;
