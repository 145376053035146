import React from 'react';

const Footer = () => {
  return (
    <div className="pb-20 py-[4rem] font-ChakraPetch px-5 lg:px-[8.75rem] md:flex justify-between border-t-[3px] border-[#4703E4] text-white">
      <ul className="space-y-2 text-left">
        <li>
          <a target='_blank' className="text-[1.5rem] lg:text-[2rem] text-white uppercase leading-[1.3] hover:text-primary transition-colors duration-200 " href="https://www.linkedin.com/company/90908235">
            Linkedin
          </a>
        </li>
        <li>
          <a target='_blank' className="text-[1.5rem] lg:text-[2rem] text-white uppercase leading-[1.3] hover:text-primary transition-colors duration-200" href="https://www.instagram.com/code_talkers_/">
            Instagram
          </a>
        </li>
        <li>
          <a target='_blank' className="text-[1.5rem] lg:text-[2rem] text-white uppercase leading-[1.3] hover:text-primary transition-colors duration-200" href="https://www.facebook.com/profile.php?id=100090391549826">
            Facebook
          </a>
        </li>
      </ul>
      <div className="space-y-8 lg:space-y-6 md:text-right mt-8 md:mt-0">
        <div className="flex flex-col md:flex-row gap-4 lg:gap-8 justify-start md:justify-end">
          <div className="text-[1.125rem] text-left md:text-end leading-[normal]">
            Code Talkers Uab<br />
            Žirmūnų g. 57-50<br />
            LT-09110 Vilnius
          </div>
          <div className="text-[1.125rem] text-left md:text-end leading-[normal]">
            Perspective srl<br />
            Viale Roma 50<br />
            Massanzago, 35010 (PD)<br />
            Pi 05153080287
          </div>
        </div>
        <div className="flex gap-6 lg:gap-8 md:justify-end">
          <div className="text-[1.125rem] text-start md:text-end font-medium leading-[1.3] uppercase">
            © 2023<br />
            CODE TALKERS
          </div>
          <div className="text-[1.125rem] font-medium leading-[1.3] uppercase">
            <a target='_blank' href="https://www.iubenda.com/privacy-policy/98581317" className="iubenda-white iubenda-nostyle iubenda-embed iubenda-noiframe no-brand !block !w-auto !h-auto hover:text-primary transition-colors duration-200" title="Privacy Policy" style={{ outline: '0px', border: '0px', textDecoration: 'none', display: 'inline-block', background: 'none', width: '116px', height: '25px' }}>Privacy Policy</a>
            <script type="text/javascript" src="https://cdn.iubenda.com/iubenda_i_badge.js"></script>
            <script type="text/javascript" dangerouslySetInnerHTML={{ __html: `(function (w, d) { var loader = function () { var s = d.createElement("script"), tag = d.getElementsByTagName("script")[0]; s.src = "https://cdn.iubenda.com/iubenda.js"; tag.parentNode.insertBefore(s, tag); }; if (w.addEventListener) { w.addEventListener("load", loader, false); } else if (w.attachEvent) { w.attachEvent("onload", loader); } else { w.onload = loader; } })(window, document);` }} />
            <a target='_blank' href="https://www.iubenda.com/privacy-policy/98581317/cookie-policy" className="iubenda-white iubenda-nostyle iubenda-embed iubenda-noiframe no-brand !block !w-auto !h-auto hover:text-primary transition-colors duration-200" title="Cookie Policy" style={{ outline: '0px', border: '0px', textDecoration: 'none', display: 'inline-block', background: 'none', width: '116px', height: '25px' }}>Cookie Policy</a>
            <script type="text/javascript" src="https://cdn.iubenda.com/iubenda_i_badge.js"></script>
            <script type="text/javascript" dangerouslySetInnerHTML={{ __html: `(function (w, d) { var loader = function () { var s = d.createElement("script"), tag = d.getElementsByTagName("script")[0]; s.src = "https://cdn.iubenda.com/iubenda.js"; tag.parentNode.insertBefore(s, tag); }; if (w.addEventListener) { w.addEventListener("load", loader, false); } else if (w.attachEvent) { w.attachEvent("onload", loader); } else { w.onload = loader; } })(window, document);` }} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
