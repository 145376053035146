import React, { useState } from 'react'

import Header from '../components/Header';
import Translator from '../components/Translator';
import FormTitle from '../components/FormTitle';
import Slider from '../components/Slider';
import Footer from '../components/Footer';
import Form from '../components/Form';

import './../App.css';

export default function Home() {
    const [text , setText ] = useState("")
    const [morse , setMorse ] = useState("")

  return (
    <div className="max-h-screen App bg-black snap-y snap-mandatory overflow-y-scroll">

      <Header></Header>

      <div className='min-h-screen snap-start'>
        <Translator text={text} morse={morse} setText={setText} setMorse={setMorse} />  
      </div>

      <div className='min-h-screen snap-start'>
        <FormTitle />
        <Form text={text} morse={morse} />
        <Slider />
        <Footer />
      </div>
    </div>
  )
}
