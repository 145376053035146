import React, { useState } from 'react';
import TextArea from './TextArea';

const Translator = (props) => {
    const { text, morse, setText, setMorse } = props

    const [isTextToMorse, setIsTextToMorse] = useState(true);
    const [isReadOnly, setIsReadOnly] = useState(false);
    const [isPlaying, setIsPlaying] = useState(false);
    const [audioContext, setAudioContext] = useState(null);
    const [oscillator, setOscillator] = useState(null);


  const textToMorse = (inputText) => {
    const morseCode = {
        'A': '.-', 'B': '-...', 'C': '-.-.', 'D': '-..', 'E': '.', 'F': '..-.', 'G': '--.', 'H': '....', 'I': '..', 'J': '.---',
        'K': '-.-', 'L': '.-..', 'M': '--', 'N': '-.', 'O': '---', 'P': '.--.', 'Q': '--.-', 'R': '.-.', 'S': '...', 'T': '-',
        'U': '..-', 'V': '...-', 'W': '.--', 'X': '-..-', 'Y': '-.--', 'Z': '--..',
        '1': '.----', '2': '..---', '3': '...--', '4': '....-', '5': '.....', '6': '-....', '7': '--...', '8': '---..', '9': '----.', '0': '-----',
        '.': '.-.-.-', ',': '--..--', '?': '..--..', "'": '.----.', '!': '-.-.--', '/': '-..-.', '(': '-.--.', ')': '-.--.-', '&': '.-...',
        ':': '---...', ';': '-.-.-.', '=': '-...-', '+': '.-.-.', '-': '-....-', '_': '..--.-', '"': '.-..-.', '$': '...-..-', '@': '.--.-.'
      };
    
      inputText = inputText.toUpperCase();
      const words = inputText.split(' ');
      const morseArray = words.map((word) => {
        const letters = word.split('');
        const morseWord = letters.map((letter) => {
          if (morseCode[letter]) {
            return morseCode[letter];
          }
          return ''; // Ignora i caratteri non riconosciuti
        }).join(' '); // Usare uno spazio tra i simboli Morse invece di ''
        return morseWord;
      });
      return morseArray.join('   '); // Aggiungi 3 spazi tra le parole
    };

  const morseToText = (inputMorse) => {
    const morseCode = {
      '.-': 'A', '-...': 'B', '-.-.': 'C', '-..': 'D', '.': 'E', '..-.': 'F', '--.': 'G', '....': 'H', '..': 'I', '.---': 'J',
      '-.-': 'K', '.-..': 'L', '--': 'M', '-.': 'N', '---': 'O', '.--.': 'P', '--.-': 'Q', '.-.': 'R', '...': 'S', '-': 'T',
      '..-': 'U', '...-': 'V', '.--': 'W', '-..-': 'X', '-.--': 'Y', '--..': 'Z',
      '.----': '1', '..---': '2', '...--': '3', '....-': '4', '.....': '5', '-....': '6', '--...': '7', '---..': '8', '----.': '9', '-----': '0',
      '.-.-.-': '.', '--..--': ',', '..--..': '?', '.----.': "'", '-.-.--': '!', '-..-.': '/', '-.--.': '(', '-.--.-': ')', '.-...': '&',
      '---...': ':', '-.-.-.': ';', '-...-': '=', '.-.-.': '+', '-....-': '-', '..--.-': '_', '.-..-.': '"', '...-..-': '$', '.--.-.': '@'
    };
  
    const words = inputMorse.split('   '); // Divide il morse in parole
    const textArray = words.map((word) => {
      const morseLetters = word.split(' ');
      const textWord = morseLetters.map((morseLetter) => {
        if (morseCode[morseLetter]) {
          return morseCode[morseLetter];
        }
        return ''; // Ignora i morse non riconosciuti
      }).join('');
      return textWord;
    });
  
    return textArray.join(' '); // Unisci le parole con spazi tra di loro
  };

  const handleTextChange = (event) => {
    const inputValue = event.target.value;
    setText(inputValue);
    if (isTextToMorse) {
      setMorse(textToMorse(inputValue));
    }
  };

  const handleMorseChange = (event) => {
    const inputValue = event.target.value;
    setMorse(inputValue);
    if (!isTextToMorse) {
      setText(morseToText(inputValue));
    }
  };

  const toggleTranslation = () => {
    setIsTextToMorse(!isTextToMorse);
    setIsReadOnly(false); // Riabilita la modifica quando si cambia la traduzione
    if (isTextToMorse) {
      setText(morseToText(morse));
    } else {
      setMorse(textToMorse(text));
    }
  };



  const generateMorseAudio = (morseCode) => {
    if (!audioContext) {
      const context = new (window.AudioContext || window.webkitAudioContext)();
      const oscillator = context.createOscillator();
      oscillator.type = 'sine';
      oscillator.frequency.setValueAtTime(800, context.currentTime);
      oscillator.connect(context.destination);

      const timeUnit = 100;

      let currentTime = context.currentTime;
      oscillator.start(currentTime);

      for (const symbol of morseCode) {
        if (symbol === '.') {
          oscillator.frequency.setValueAtTime(200, currentTime);
          currentTime += timeUnit / 1000;
          oscillator.frequency.setValueAtTime(0, currentTime);
          currentTime += timeUnit / 1000;
        } else if (symbol === '-') {
          oscillator.frequency.setValueAtTime(200, currentTime);
          currentTime += (timeUnit * 3) / 1000;
          oscillator.frequency.setValueAtTime(0, currentTime);
          currentTime += timeUnit / 1000;
        } else if (symbol === ' ') {
          currentTime += (timeUnit * 3) / 1000;
        }
      }
      console.log(context.state);
      oscillator.stop(currentTime);
      oscillator.onended = () => {
        // Quando l'audio finisce, reimposta lo stato
        setAudioContext(null);
        setOscillator(null);
        setIsPlaying(false);
      };

      setAudioContext(context);
      context.onstatechange = () => {
        if (context.state === 'closed') {
          setAudioContext(null);
          setIsPlaying(false);
        }
      };
    }
  };

  const handlePlayMorseAudio = () => {
    if (isPlaying) {
      // Se l'audio è in riproduzione, interrompi l'AudioContext e reimposta lo stato
      if (audioContext && audioContext.state === 'running') {
        audioContext.close();
      }
    } else {
        setIsPlaying(true);
        generateMorseAudio(morse);
        
    }
  };

  
  return (
    <div className="flex justify-center lg:h-screen items-center bg-[#4703E4] rounded-3xl">
      <div className="w-full py-[7rem] px-[1rem] font-ChakraPetch  max-w-[1430px]">
        <h2 className="text-[2.2rem] md:text-[3rem] text-white font-[500] mb-[1rem]">Scrivi il tuo messaggio e traducilo in <span className='font-BluuNext italic font-[700]'>in codice Morse.</span></h2>
        <p className='text-white text-[1.8rem] mb-10'>Puoi anche riprodurne il suono, o invertire il verso della traduzione.</p>
        <div className="flex flex-col mx-auto md:space-x-4 md:flex-row gap-6 md:gap-0">
            <div className='md:hidden flex justify-center items-center gap-10 text-white text-[1.2rem] text-left py-[1rem] px-[2rem] whitespace-nowrap '>
              {isTextToMorse ? 'ITALIANO' : 'CODICE MORSE'} 
              <div className="flex justify-center h-10 w-0">
              <button
                className="px-2 py-2 bg-black text-white rounded-full"
                onClick={toggleTranslation}
              >
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M7.5 21L3 16.5m0 0L7.5 12M3 16.5h13.5m0-13.5L21 7.5m0 0L16.5 12M21 7.5H7.5" />
                </svg>
              </button>
            </div>
            {isTextToMorse ? 'CODICE MORSE' : 'ITALIANO'}
            </div>  
            <TextArea
              label={isTextToMorse ? 'ITALIANO' : 'CODICE MORSE'}
              placeholder={isTextToMorse ? 'Inserisci il testo' : 'Codice Morse'}
              morse={!isTextToMorse ? 'font-[900]' : ''}
              value={isTextToMorse ? text : morse}
              onChange={isTextToMorse ? handleTextChange : handleMorseChange}
              readOnly={isReadOnly}
            >
              <button
                className={`absolute bottom-[0.5rem] right-[0.5rem] px-5 py-5 bg-black text-white rounded-full ${isPlaying ? 'bg-red-500' : ''} ${isTextToMorse ? 'hidden' : ''}`}
                onClick={handlePlayMorseAudio}
              >
                  {isPlaying ? 
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M5.25 7.5A2.25 2.25 0 017.5 5.25h9a2.25 2.25 0 012.25 2.25v9a2.25 2.25 0 01-2.25 2.25h-9a2.25 2.25 0 01-2.25-2.25v-9z" />
                  </svg>
                  : 
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M19.114 5.636a9 9 0 010 12.728M16.463 8.288a5.25 5.25 0 010 7.424M6.75 8.25l4.72-4.72a.75.75 0 011.28.53v15.88a.75.75 0 01-1.28.53l-4.72-4.72H4.51c-.88 0-1.704-.507-1.938-1.354A9.01 9.01 0 012.25 12c0-.83.112-1.633.322-2.396C2.806 8.756 3.63 8.25 4.51 8.25H6.75z" />
                  </svg>}
              </button>
            </TextArea>
                    
            <div className="justify-center mt-2 h-[3rem] w-0 hidden md:flex">
              <button
                className="px-3 py-3 bg-black text-white rounded-full"
                onClick={toggleTranslation}
              >
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M7.5 21L3 16.5m0 0L7.5 12M3 16.5h13.5m0-13.5L21 7.5m0 0L16.5 12M21 7.5H7.5" />
                </svg>
              </button>
            </div>
          
            <TextArea
              label={isTextToMorse ? 'CODICE MORSE' : 'ITALIANO'}
              placeholder={isTextToMorse ? 'Codice Morse' : 'Testo tradotto'}
              morse={isTextToMorse ? 'font-[900]' : ''}
              value={isTextToMorse ? morse : text}
              onChange={isTextToMorse ? handleMorseChange : handleTextChange}
              readOnly={!isReadOnly}
            >
              <button
                className={`absolute bottom-[0.5rem] right-[0.5rem] px-5 py-5 bg-black text-white rounded-full ${isPlaying ? 'bg-red-500' : ''} ${!isTextToMorse ? 'hidden' : ''}`}
                onClick={handlePlayMorseAudio}
              >
                  {isPlaying ? 
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M5.25 7.5A2.25 2.25 0 017.5 5.25h9a2.25 2.25 0 012.25 2.25v9a2.25 2.25 0 01-2.25 2.25h-9a2.25 2.25 0 01-2.25-2.25v-9z" />
                  </svg>
                  : 
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M19.114 5.636a9 9 0 010 12.728M16.463 8.288a5.25 5.25 0 010 7.424M6.75 8.25l4.72-4.72a.75.75 0 011.28.53v15.88a.75.75 0 01-1.28.53l-4.72-4.72H4.51c-.88 0-1.704-.507-1.938-1.354A9.01 9.01 0 012.25 12c0-.83.112-1.633.322-2.396C2.806 8.756 3.63 8.25 4.51 8.25H6.75z" />
                  </svg>}
              </button>
            </TextArea>
          
        </div>
      </div>
    </div>
  );
};

export default Translator;