import React from 'react';

const FormTitle = () => {

return (
    <div className="pt-[6rem] px-[2rem] md:px-[5rem] text-white w-full flex flex-col justify-center items-center font-ChakraPetch max-w-[1430px] mx-auto">
        <h1 className="text-[2rem] mb-[1rem] md:text-[2.5rem]  text-center font-[600] leading-[2.8rem] md:leading-[4rem]">
            Invia il tuo messaggio criptato <span className='font-BluuNext italic font-[700]'>a chi vuoi tu</span>
        </h1>
        <span className='font-[400] md:text-[1.5rem]'>
        Compila il form con i tuoi dati insieme a quelli del destinatario. 
        </span>
    </div>
    );
};

export default FormTitle;