import React from 'react'
import { RouterProvider, createBrowserRouter} from 'react-router-dom'

import Home from './pages/Home'
import ThankYou from './pages/ThankYou'
import MorseReveal from './pages/MorseReveal'

export default function Routes() {
  
    const router = createBrowserRouter([
        { path: "/", element: <Home />, },
        { path: "/thankyou", element: <ThankYou />, },
        { path: "/morseReveal", element: <MorseReveal />, },
    ]);

  return (
    <RouterProvider router={router} />
  )
}
