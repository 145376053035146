// InputField.js

import React from 'react';

function InputField({ label, type, name, value, onChange, placeholder, required }) {
  return (
    <div className="mb-5 w-full">
      <label className="block text-sm font-normal mb-2">
        {label}
        <input
          type={type}
          name={name}
          value={value}
          onChange={onChange}
          className="form-input text-black text-3xl py-6 min-h-[6.25rem] px-10 rounded-full mt-1 block w-full  placeholder:text-black"
          placeholder={placeholder}
          required={required}
        />
      </label>
    </div>
  );
}

export default InputField;
