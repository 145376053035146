import React, { useState }  from 'react';

const Slider = () => {

    const [isHovered, setIsHovered] = useState(false);

  return (
    <div id="marquee2-mobile"  className={`py-[2rem] marqueeDur w-full overflow-x-hidden bg-black  ${isHovered ? 'paused' : ''}`}
    data-runningstatus="resumed"
    onMouseEnter={() => setIsHovered(true)}
    onMouseLeave={() => setIsHovered(false)}>
      <div style={{ width: '100%', transform: 'translateX(0px)' }} className="js-marquee-wrapper">
        <div className="js-marquee flex" style={{ marginRight: '0px', willChange: 'transform', float: 'left' }}>
          <img className="marquee-img h-[5rem] w-full md:h-full inline-block" src="https://codetalkers.eu/wp-content/uploads/2023/05/logoloop.png" alt="Logo" />
          <img className="marquee-img h-[5rem] w-full md:h-full inline-block" src="https://codetalkers.eu/wp-content/uploads/2023/05/logoloop.png" alt="Logo" />
          <img className="marquee-img h-[5rem] w-full md:h-full inline-block" src="https://codetalkers.eu/wp-content/uploads/2023/05/logoloop.png" alt="Logo" />
          <img className="marquee-img h-[5rem] w-full md:h-full inline-block" src="https://codetalkers.eu/wp-content/uploads/2023/05/logoloop.png" alt="Logo" />
          <img className="marquee-img h-[5rem] w-full md:h-full inline-block" src="https://codetalkers.eu/wp-content/uploads/2023/05/logoloop.png" alt="Logo" />
          <img className="marquee-img h-[5rem] w-full md:h-full inline-block" src="https://codetalkers.eu/wp-content/uploads/2023/05/logoloop.png" alt="Logo" />
          <img className="marquee-img h-[5rem] w-full md:h-full inline-block" src="https://codetalkers.eu/wp-content/uploads/2023/05/logoloop.png" alt="Logo" />
        </div>
        
      </div>
    </div>
  );
}

export default Slider;
