import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import Slider from '../components/Slider'
import Footer from '../components/Footer'
import logo from "../assets/img/logo.png"
import useMorse from '../hooks/useMorse'

export default function MorseReveal() {
   
    const location = useLocation();
    const params = new URLSearchParams(location.search);

    const text = params.get('text');
    const morse = params.get('morse');

    const { playMorseAudio, isPlaying } = useMorse(atob(morse))
   
    return (
        <div className=' pt-[5rem] bg-black font-ChakraPetch'>
            <img src={logo} alt="" className=" pt-5 px-[4rem] mx-auto" />
            <div className='px-[2rem] py-[4rem] max-w-[800px] mx-auto flex flex-col text-left justify-center items-center gap-5 my-[4rem]'>
                <span className='text-white text-[2.5rem] text-center font-ChakraPetch'>
                    {text}
                </span>

                <button
                    className={` px-5 py-5 bg-[#F34749] my-[2rem] text-white rounded-full ${isPlaying ? '!bg-[#4703E4]' : ''}`}
                    onClick={playMorseAudio}
                >
                    {isPlaying ? 
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M5.25 7.5A2.25 2.25 0 017.5 5.25h9a2.25 2.25 0 012.25 2.25v9a2.25 2.25 0 01-2.25 2.25h-9a2.25 2.25 0 01-2.25-2.25v-9z" />
                  </svg>
                  : 
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M19.114 5.636a9 9 0 010 12.728M16.463 8.288a5.25 5.25 0 010 7.424M6.75 8.25l4.72-4.72a.75.75 0 011.28.53v15.88a.75.75 0 01-1.28.53l-4.72-4.72H4.51c-.88 0-1.704-.507-1.938-1.354A9.01 9.01 0 012.25 12c0-.83.112-1.633.322-2.396C2.806 8.756 3.63 8.25 4.51 8.25H6.75z" />
                  </svg>}
                </button>

                <span className='text-white text-[2.5rem] text-center font-ChakraPetch' >
                    { atob(morse) }
                </span>
                
                <Link to={`/`} className="relative overflow-hidden hover:text-[#000000] transition-all duration-500 mx-auto mt-[3rem] font-[500] bg-[#4703E4] text-white py-[1.81rem] px-[3.125rem] text-[2rem] flex gap-2 rounded-full items-center btn" >
                   <span  className='z-[2] relative'>Invia un tuo messaggio</span>
                </Link>
            </div>

            <Slider />
            <Footer />
        </div>
    )
}
