import React from 'react';
import logo from "../assets/img/logo.png"
const Header = () => {

return (
    <div className="snap-start py-[9.38rem] w-full flex lg:h-screen flex-col justify-center items-center font-ChakraPetch max-w-[1440px] mx-auto">
        <img src={logo} alt="" className="px-[4rem]" />
        <h1 className="text-white text-[1.7rem] md:text-[3rem] px-[2rem] md:px-[2rem] pt-[2.5rem] md:pt-[4rem] text-center font-[400] leading-[2.8rem] md:leading-[4rem]">
        Benvenuto nel <span className='font-BluuNext italic font-[700]'>Morse Talker,</span> il tool per inviare messaggi criptati. <br></br> 
        I <span className='font-BluuNext italic font-[700]'>Code Talkers</span> erano i soldati <span className='font-BluuNext italic font-[700]'>Navajo</span> che utilizzavano il <span className='font-BluuNext italic font-[700]'>codice Morse</span> insieme alla loro lingua madre per criptare le comunicazioni USA nella Seconda Guerra Mondiale. 
        </h1>
    </div>
    );
};

export default Header;