import React, { useState } from 'react'

export default function useMorse(morseCode) {

    const [isPlaying, setIsPlaying] = useState(false)
    const [audioContext, setAudioContext] = useState(null)
    const [oscillator, setOscillator] = useState(null)

    const generateMorseAudio = () => {
        if (!audioContext) {
          const context = new (window.AudioContext || window.webkitAudioContext)();
          const oscillator = context.createOscillator();
          oscillator.type = 'sine';
          oscillator.frequency.setValueAtTime(800, context.currentTime);
          oscillator.connect(context.destination);
    
          const timeUnit = 100;
    
          let currentTime = context.currentTime;
          oscillator.start(currentTime);
    
          for (const symbol of morseCode) {
            if (symbol === '.') {
              oscillator.frequency.setValueAtTime(200, currentTime);
              currentTime += timeUnit / 1000;
              oscillator.frequency.setValueAtTime(0, currentTime);
              currentTime += timeUnit / 1000;
            } else if (symbol === '-') {
              oscillator.frequency.setValueAtTime(200, currentTime);
              currentTime += (timeUnit * 3) / 1000;
              oscillator.frequency.setValueAtTime(0, currentTime);
              currentTime += timeUnit / 1000;
            } else if (symbol === ' ') {
              currentTime += (timeUnit * 3) / 1000;
            }
          }
          console.log(context.state);
          oscillator.stop(currentTime);
          oscillator.onended = () => {
            // Quando l'audio finisce, reimposta lo stato
            setAudioContext(null);
            setOscillator(null);
            setIsPlaying(false);
          };
    
          setAudioContext(context);
          context.onstatechange = () => {
            if (context.state === 'closed') {
              setAudioContext(null);
              setIsPlaying(false);
            }
          };
        }
    };
    
      const playMorseAudio = () => {
        if (isPlaying) {
          // Se l'audio è in riproduzione, interrompi l'AudioContext e reimposta lo stato
          if (audioContext && audioContext.state === 'running') {
            audioContext.close();
          }
        } else {
            setIsPlaying(true);
            generateMorseAudio(morseCode);
            
        }
      }

    return {
        playMorseAudio,
        isPlaying
    }

}
