import React from 'react'
import { Link } from 'react-router-dom'


export default function ThankYou() {
    return (
        <div className='text-left h-[100vh] flex justify-center items-center px-[2rem] py-[2rem]'>
            <div className='max-w-[800px] mx-auto flex flex-col text-left justify-center items-center gap-5'>
                <h1 className='text-[6rem] font-[700] font-BluuNext text-left w-full'>
                    Ben fatto!
                </h1>

                <h2 className='text-[2.8rem] font-[500] font-ChakraPetch text-left  w-full'>
                    Grazie della fiducia.
                </h2>

                <p className='text-[1.8rem] pb-[2rem] font-[400] font-ChakraPetch text-left  w-full'>
                    Tieni d'occhio la mail, non vediamo l’ora di approfondire
                    la conoscenza e mostrarti cosa possiamo fare per te.
                </p>
                
                <Link to={`/`} className="relative overflow-hidden hover:text-[#000000] transition-all duration-500 mr-auto font-[500] bg-[#4703E4] text-white py-[1.81rem] px-[3.125rem] text-[2rem] flex gap-2 rounded-full items-center btn" >
                    <span  className='z-[2] relative'>Torna alla home</span>
                </Link>
            </div>


        </div>
    )
}
